.admin-ip-form {
    align-items: baseline;
    padding-top: 10px;
    padding-bottom: 10px;
    label {
        display: block;
        margin-top: 5px;
        font-weight: 500;
        text-align: left;
        font-size: 14px; } }

.ip-form {
  align-items: baseline;
  padding: 4px 15px 10px 15px; }

.ip-reset {
    text-align: right; }

.page-title {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 10px; }

.integration-partner-configure-tab {
  display: flex;
  align-items: center;
  align-content: flex-start;
  border: none !important;
  padding: 0 10px 10px 10px; }

.admin-sub-tab-container {
  border: 1px solid #d2d2d2;
  padding: 15px; }

.admin-sub-tab {
  padding: 10px;
  text-align: center;
  background-color: #c0c0c026;
  border-top: 4px solid white; }

.admin-sub-tab-active {
  background-color: #80808042;
  color: black;
  border-top: 4px solid white; }

.admin-form-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 14px; }

.admin-form-section-title {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 8px; }

.admin-inline-action {
  display: flex;
  align-items: flex-end;
  cursor: pointer; }

.admin-action-label {
  padding-left: 5px; }

.admin-form {
    line-height: 1; }

.admin-form label {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  text-align: left;
  font-size: 14px; }

.admin-actions {
  padding: 12px;
  margin-top: 5px;
  text-align: left; }

.admin-actions a {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  padding: 9px 14px; }

.admin-actions-fieldset {
  text-align: left;
  padding-bottom: 15px; }

.admin-actions-fieldset a {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  padding: 9px 14px; }

.integration-partner-form {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px;
  input, select {
    width: 400px;
    margin-right: 25px;
    height: 30px;
    margin-bottom: 6px; }
  &.deployment-tab-form select {
    width: 200px; } }

.pref-integration-partner-form {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 10px; }

.input-field-pwd {
    width: 90%;
    margin-bottom: 15px;
    margin-top: 5px; }

.admin-error {
  color: red;
  padding-bottom: 10px; }

.admin-fieldset {
    border: solid 1px #dadada; }

.admin-user-icons {
    font-size: 18px;
    position: relative;
    top: 2px; }

.admin-input {
  position: relative; }

.admin-input-90 {
  width: 90%; }

.admin-input-margin {
  margin-top: 5px;
  margin-bottom: 15px; }

.admin-color-set-scroller {
    position: relative;
    overflow: auto;
    background-color: white;
    max-height: 500px;
    width: 715px /*for chrome bug */; }

.user-action-overflow-menu {
    position: absolute;
    background-color: white;
    right: 0;
    padding: 5px;
    border: 1px solid silver;
    z-index: 20; }

.user-action-overflow-menu > div:first-child {
    margin: 0 !important; }
