body {
    background: $light_gray;
    font-family: Montserrat, Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.38;
    color: #333; }

input::placeholder {
    font-family: Montserrat, Arial, sans-serif; }

a {
    color: #333;
    text-decoration: none;
    &:hover {}
    color: #333; }

h3 {
    font: 500 18px Montserrat, Arial, sans-serif;
    color: $dark_gray; }

h4 {
    font: 500 16px/1.63 Montserrat, Arial, sans-serif;
    color: $dark_gray; }

h5 {
    font: 500 15px/1.33 Montserrat, Arial, sans-serif;
    color: $dark_gray; }

h6 {
    font: 500 14px/1.33 Montserrat, Arial, sans-serif;
    color: $dark_gray;
    text-transform: uppercase; }

#main-display-container {
    position: fixed;
    overflow-y: scroll;
    right: 0;
    bottom: 0;
    top: 50px;
    left: 5%;
    transition: all .5s; }

.main-tooltip {
    pointer-events: auto !important;
    transition: opacity .6s ease-in-out !important;
    z-index: 9999999 !important;
    &:hover {
        visibility: visible !important;
        opacity: 1 !important; } }

#app-container {
    height: 100%; }

.grid-action-container {
    padding-bottom: 5px;
    display: flex;
    align-items: center; }

.display-table {
    display: table; }
.display-table-row {
    display: table-row; }
.display-table-cell {
    display: table-cell; }
.height-1 {
    height: 1px; }
.height-0 {
    height: 0; }
.height-auto {
    height: auto; }
.height-full {
    height: 100%; }
.width-full {
    width: 100%; }
.width-half {
    width: 50%; }
.height-half {
    height: 50%; }
.relative {
    position: relative; }
.absolute {
    position: absolute; }
.bg-white {
    background-color: white; }

.pref-green-color {
    color: $pref_green; }
