.content-list-scroller {
    position: absolute;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto; }

.settings-list-scroller {
    position: absolute;
    top: 158px;
    bottom: 0;
    left: 5px;
    right: 0;
    overflow: auto;
    text-align: center;
    padding: 0 15px 0 15px; }

.settings-list-scroller-colors {
    top: 143px; }

.content-list-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    user-select: none;
    .fa-envelope-o {
        color: silver; } }

.content-list-flex-item {
    user-select: none;
    width: 135px;
    border: 1px solid #D4D4D4;
    margin: 8px;
    padding: 8px; }

.content-thumbnail {
    text-align: center;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; }

.content-thumbnail-placeholder {
    color: silver;
    font-size: 32px; }

.content-label {
    margin-top: 10px;
    text-align: center;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; }

.unselectable {
    user-select: none; }

.content-icon {
    font-size: 32px !important;
    .fa-icons {
        font-family: 'FA Content Components';
        font-style: normal; } }

.settings-table-list {
    width: 100%;
    td {
        padding: 10px;
        text-align: left; }
    input :not(.react-select__input) {
        height: 30px;
        text-indent: 4px;
        width: 100%;
        background-color: white !important;
        border: 1px solid #cccccc !important; }
    textarea {
        height: 50px !important;
        padding: 6px !important;
        width: 100% !important;
        background-color: white !important;
        border: 1px solid #cccccc !important; }
    th {
        padding-bottom: 10px;
        font-weight: 500;
        &.mapping-th {
            padding-bottom: 15px; } } }

.props-panel {
    text-align: left;
    input {
        height: 30px;
        text-indent: 4px;
        width: 100%; }
    label {
        font-weight: 500;
        margin-bottom: 5px;
        text-align: left;
        font-size: 14px;
        &.panel-title {
            margin-top: 0;
            text-align: center; } } }


.mapping-panel {
    padding: 0 10px 0 10px; }

.mapping-section-header {
    text-align: left;
    font-weight: 500;
    padding-bottom: 15px; }

.pref-inline-editor {
    flex: 1;
    white-space: nowrap;
    overflow: hidden; }

.props-not-available {
    top: 0;
    background-color: rgba(234, 234, 234, 0.92);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    > div {
        border: 1px solid black;
        padding: 5px;
        background-color: white; } }

.config-block {
    position: relative;
    cursor: grab; }

.config-block:hover .config-block-controls {
    z-index: 1000;
    outline: 2px solid $pref_green;
    display: block; }

.config-block-controls {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    transition: all .5s ease; }

.config-header {
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 10px;
  background-color: #e8e8e8eb;
  font-family: Montserrat, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500; }

.config-content-controls-icon {
  position: absolute;
  top: 5px;
  right: 7px;
  width: 25px !important;
  cursor: pointer;
  z-index: 500;
  transition: all .5s ease;
  &.config-material-edit-icon {
    font-size: 21px; } }

.invalidConfigMsg {
    white-space: nowrap;
    padding: 0 3px;
    margin-right: 10px;
    background-color: rgba(199, 43, 43, 0.97);
    color: white;
    span.msg-action {
        cursor: pointer; }
    span.inlineHelpContainer {
        font-size: 17px; } }

//used by preferences
//.content-block
//    position: relative
//    cursor: grab

//.content-controls-icon
//    position: absolute
//    top: 4px
//    right: 5px
//    width: 25px
//    font-size: 21px !important
//    cursor: pointer
//    color: #333
//    z-index: 500
//    transition: all .5s ease
//    &.material-icons
//        right: 30px
//        color: #333
//    &.edit-only
//        right: 5px

//.content-block:hover .content-block-controls
//    z-index: 1000
//    outline: 2px solid $pref_green
//    display: block
//    min-height: 33px
//    &.email-experience-controls
//        height: 100%
//        background-color: #c0c0c0ba

//.content-block:hover .content-block-controls .content-header i
//    color: black

//.content-block:hover .content-block-controls .content-header svg
//    color: black

//.content-block:hover .content-block-controls .content-header div
//    color: black
//    position: absolute
//    transition: all .5s ease
//    z-index: 500

//.content-block:hover .content-block-controls .content-header
//    padding-bottom: 26px

//.content-block-controls
//    display: none
//    position: absolute
//    top: 0
//    left: 0
//    width: 100%
//    z-index: 5
//    transition: all .5s ease

.listOrder {
    border: 1px solid silver;
    margin-bottom: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    list-style-type: none;
    cursor: grab;
    text-align: center; }

.listOrderHelper {
    background-color: lightgrey; }

.tlbr-inputs {
    width: 60px;
    height: 30px;
    text-indent: 4px;
    border: 1px solid #d2d2d2; }

.tlbr-container {}


.tlbr-prop {
    margin-bottom: 10px; }

.design-device {
    display: inline-block;
    border: 1px solid transparent;
    &.on {
        border: 1px solid #757575; } }
