#page-content {
    top: 40px;
    left: 40px;
    position: fixed;
    height: calc(100% - 40px);
    width: calc(100% - 40px); }

#menu_active {
  position: absolute; }

#wrapper {
  padding-left: 220px;
  transition: all .5s; }

#menu_active:checked + #wrapper {
  padding-left: 35px;
  transition: all .5s; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  background-color: $dark_gray;
  transition: all 1s;
  z-index: 100; }

#menu_active:checked + #wrapper {
  #menu {
    left: -160px;
    transition: all .5s; }
  .fa-bars {
    display: block;
    left: 260px; } }

#wrapper i.fa-arrow-left {
  display: block;
  left: 260px; }

#menu_active:checked + #wrapper .fa-arrow-left {
  display: none; }

#menu i {
  width: 40px;
  text-align: center; }

#site-name {
  color: white;
  display: block;
  padding: 10px;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 40px;
  padding-right: 0;
  i {
    float: right; }
  label {
    color: white;
    font-size: 30px; } }

#menu ul {
  padding: 0;
  li {
    color: $med_gray !important;
    list-style: none;
    padding-right: 0;
    padding-left: 10px;
    i {
      font-size: 24px; } }
  a {
    text-decoration: none;
    color: $med_gray;
    display: block;
    height: 100%;
    padding-left: 10px; } }

.active-icon {
  display: block !important; }

.inactive-icon {
  display: none !important; }

#site-name img {
  height: 37px; }

#fixed-bottom {
  position: absolute;
  bottom: 20px;
  right: 10px !important;
  display: block;
  color: white;
  width: 30px; }

#p-icon {
  position: absolute;
  top: 10px;
  right: 8px; }

label .fa-angle-double-left {
  padding-left: 10px; }

.expanded:hover, .contracted:hover {
  cursor: pointer; }

#menu li:hover a {
  color: white; }

#menu li:hover i {
  color: white; }

#menu li ul {
  display: none;
  padding: 0; }

#menu ul {
  li {
    color: $med_gray !important; } }

#main-sidebar-display-container {
  position: fixed;
  overflow-y: scroll;
  right: 0;
  bottom: 0;
  top: 40px;
  left: 250px;
  transition: all .5s; }
