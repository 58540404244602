.fa {  //global fa font props
  font-size: 22px;
  line-height: 1;
  font-weight: 400; }

.material-icons {  //global material icon props
  font-size: 27px;
  font-weight: 400;
  line-height: 1; }

.link-icon {  //preferences home page
  background-color: $pref_green;
  border-radius: 100px;
  display: inline-block;
  padding: 3px 10px 5px;
  text-align: center; }

.deployment-icons {
    font-size: 20px !important; }

@font-face {
  font-family: 'FA Content Components';
  font-style: normal;
  font-weight: 400;
  src: url('#{$HOST}/dist/fonts/font-awesome-content-components.woff2') format("woff2"); }
