.sidebar {
    top: 40px;
    bottom: 0;
    background: #fff;
    padding: 16px;
    width: 210px;
    position: fixed;
    z-index: 10;
    overflow-y: auto; }

// used in pref sidebar impl and sidebar impl
.sidebar-section {
    list-style-type: none;
    padding-bottom: 20px;
    .sidebar-section-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        padding-bottom: 10px;
        text-transform: uppercase; }
    .sidebar-selected {
        font-weight: bold;
        color: black; }
    .sidebar-section-body {
        padding-left: 20px; }
    li {
        list-style-type: none; }
    .menu-items {
        color: $dark_gray;
        line-height: 2;
        text-transform: none; } }





