.btn {
  display: inline-block;
  background: $dark_gray;
  border-radius: 25px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  position: relative;
  text-transform: uppercase;
  border: 0;
  z-index: 5;
  -webkit-transition: background .4s, color .4s ease;
  -o-transition: background .4s, color .4s ease;
  transition: background .4s, color .4s ease;
  &:hover {
    text-decoration: none;
    color: #fff;
    background: $pref_green; }
  i {
    margin-right: 8px;
    line-height: 0.8; }
  &.btn-gray {
    font-size: 9.5px;
    color: #919a9f;
    font-weight: 500;
    line-height: 1;
    padding: 6px 15px 8px;
    background: #f7f7f7;
    border-radius: 100px;
    overflow: hidden;
    &:before {
      content: '';
      background: #f7f7f7; }
    span {
      position: relative;
      z-index: 5;
      color: #919a9f;
      -webkit-transition: all .4s ease;
      -o-transition: all .4s ease;
      transition: all .4s ease; }
    &:after {
      content: '';
      background: $pref_green;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -100%;
      -webkit-transition: all .4s ease;
      -o-transition: all .4s ease;
      transition: all .4s ease; }
    &:hover {
      text-decoration: none;
      span {
        color: #fff; }
      &:after {
        left: 0; } } }
  &.btn-std {
    background: #43545c;
    &:hover {
      background: $dark_gray; } }
  &.disabled {
    color: #717171; }
  &.green {
    background-color: $pref_green; } }

#sub-display-button, #data-ext-display-button, #email, #web, #mobile-preview-button, #desktop-preview-button {
  &.active-modal-button {
    background-color: $pref_green !important;
    color: #fff !important; } }

#sub-model-btns {
  &.section-actions {
    text-align: center !important; }
  .example-btn-style {
    padding: 9px 14px;
    background-color: #A5ADB1;
    &:hover {
      background-color: #43545c; } } }
