#design-tab-properties {
  font-size: 14px;
  color: #333;
  margin: 0 auto;
  padding: 20px;
  // .design-tab-heading
  .sidebar-input-section {
    display: block;
    font-size: 14px;
    margin: auto;
    width: 95%;
    p, input {
      display: inline; }
    input {
      padding: 5px;
      width: 100%; } }
  h6 {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400; }
  .row {
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 16px;
    padding: 0 20px 10px; }
  .design-tab-heading {
    margin: 0;
    .property-title {
      vertical-align: middle;
      color: $pref_green;
      font-weight: 500;
      text-align: center;
      font-size: 14px;
      font-family: sans-serif;
      text-transform: none; }
    .property-section-title {
      vertical-align: middle;
      color: $pref_green;
      font-weight: 500;
      text-align: center;
      font-size: 14px;
      font-family: sans-serif;
      text-transform: none; } } }

.property-title {
  padding-left: 10px;
  padding-bottom: 5px; }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0); }

  40% {
    transform: translateX(-10px); }

  60% {
    transform: translateX(-5px); } }




.icon-chevron-color {
  color: silver;
  margin-right: 20px; }

.icon-chevron-color-up {
  color: silver; }

.icon-chevron-enabled {
    color: #6e797f; }

.icon-chevron-disabled {
    color: #e2e2e2; }

.bounce {
  animation: bounce 2s 5; }

.property-type {
  text-align: center;
  width: 100%;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 14px; }

.content-property-type {
  text-align: center;
  width: 100%;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 14px; }

.property-panel-container {
  padding: 15px; }

#content-instruction {
  padding-top: 20px;
  padding-left: 10px;
  display: table; }

.property-small-text {
  font-size: 10px; }

.property-sub-section-title {
  padding: 8px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: middle;
  color: #6e797f;
  text-align: center;
  font-size: 14px;
  font-family: Montserrat, Arial, sans-serif;
  text-transform: none; }

.property-section-highlight {
    background-color: #d6d6d640;
    font-weight: 500;
    color: $pref_green; }

.general-info-panel {
  text-align: left !important;
  border: 1px solid #e0e0e0;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 15px !important;
  label {
    margin-top: 0 !important;
    padding-bottom: 10px; } }

.content-property-top-sections {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 15px; }

.content-property-sub-section-title {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    padding: 8px; }

.content-property-section-highlight {
  background-color: #d6d6d640;
  font-weight: 500;
  color: $pref_green; }

.selectedSettingsPanel {
    border-bottom: 2px solid $pref_green; }

.content-property-sub-section-container {
    padding: 0 20px 20px 0; }

.content-property-sub-section-panel-section {
    padding: 15px 10px 0 10px; }

.sub-section-property-spacer {
    padding-left: 15px; }

.sub-section-property-panel {}

.content-property-sub-section-panel {
    padding-top: 15px; }

.page-settings-list-scroller {
    position: absolute;
    top: 110px;
    bottom: 0;
    left: 20px;
    right: 0;
    overflow: auto; }

.color-picker-clear {
    color: #ccc;
    xposition: relative;
    xtop: 2px; }

.color-picker-clear:hover {
    color: #999; }

.color-palette-item {
    padding-bottom: 15px;
    padding-right: 15px; }

.color-palettes-container {}

.color-palettes-container .content-property-sub-section-panel-section {
    padding: 15px 8px 0 8px; }

.color-palettes-no-colors {
    padding-bottom: 15px !important; }

.config-option {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #e0e0e0; }

.icon-disabled {
    opacity: .5; }
