$base-line-height: 35px;
$white: #696969;
$off-white: rgba($white, 0.2);
$spin-duration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {	
  50% { 
    background: $white;
  } 
}

.loading {
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .25rem solid $off-white;
    border-top-color: #37a943;
    animation: spin $spin-duration infinite linear;
    margin: auto;
    position: relative;
    top: 40%;
    transform: translateY(-40%);
  &.absolute-center {
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: 50%;
      margin-left: -25px;      
  }
  &.hidden {
      display: none
  }
}

.loading-background {
    border: 1px solid #d8d9d8;
    background-color: white;
    box-shadow: 3px 13px 15px -2px rgba(0, 0, 0,.5);
    padding: 10px;
    z-index: 100000;
}

.loading-inline{
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .25rem solid $off-white;
    border-top-color: #37a943;
    animation: spin $spin-duration infinite linear;
    margin-top: 5px;
    margin-left: 5px;
}