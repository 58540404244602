.active-template-type {
  font-weight: bold; }

.template-types {
  border-bottom: 1px solid silver;
  padding-bottom: 24px; }

.template-types-container {
  display: flex;
  position: absolute;
  height: 25px; }

.template-type {
  position: relative;
  &:hover {
    border-bottom: 1px solid #737373; } }

/* change above as well */
.active-template-type-hover-effect {
  border-bottom: 1px solid #737373; }

.template-flex-item {
  margin-right: 15px;
  margin-top: 15px;
  width: 200px; }

.template-label {
  margin-top: 10px;
  text-align: center; }

.template-thumbnail {
  height: 225px;
  text-align: center;
  border: 1px solid silver;
  cursor: pointer; }

.template-thumbnail-placeholder {
  color: #a5adb1;
  font-size: 80px; }

.selected-template {
  border: 3px solid #999 !important;
  padding: 3px; }

.template-container {
  border: 3px solid transparent; }

.template-category {
  margin-top: 15px;
  font-weight: bold; }
