.nameInput {
    width: 100%;
    background: $pref_green;
    border: none;
    font-weight: 500;
    color: white;
    font-size: 16px; }

.nameInput::placeholder {
    font-weight: 500;
    color: white;
    font-size: 16px; }

.cont-save-indicator {
    margin-left: 20px; }


.experienceName {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px; }

.preferences {
  margin: 0 -10px; }

.pref-deployment-options {
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%; }

.preference {
  list-style-type: none;
  background-color: white;
  width: 100%;
  padding: 18px;
  position: relative;
  h5 {
    padding-bottom: 12px;
    a {
      color: $dark_gray;
      &:hover {
        text-decoration: none;
        color: $pref_green; } } }
  .preference-container {
    background: #fff;
    position: relative;
    padding: 0;
    margin: 0; }

  .nav-link {
    position: absolute;
    top: -4px;
    right: -7px;
    opacity: .5;
    display: inline-block;
    padding: 5px;
    background: url(../images/ico-ellipse.png) no-repeat center;
    width: 19px;
    height: 30px; }
  .preference-meta-table {
    display: table; }
  .preference-meta {
    color: #9ca2a5;
    font-size: 9px;
    font-weight: 300;
    line-height: 1;
    p {
      display: inline-block;
      vertical-align: middle;
      padding-top: 3px;
      i {
        margin: -1px 1px 0 0; } }
    .link-icon {
      margin-right: 7px; } }
  .list-result {
    padding: 16px 0 5px; } }

.sfmc-field-mapping {
    a {
        &.btn-inactive {
            padding: 8px 12px;
            border-radius: 35px;
            background-color: #A5ADB1;
            color: #1F343D;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            transition: background .4s, color .4s ease;
            &:hover {
                color: white;
                background-color: #43545c; } }
        &.btn-active {
            padding: 8px 12px;
            border-radius: 35px;
            background-color: $pref_green;
            color: white;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px; } } }

.preference-nav {
  text-align: center;
  background: #17272d;
  position: absolute;
  top: -4px;
  right: 12px;
  z-index: 20;
  width: 180px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .4s, visibility .4s ease;
  -o-transition: opacity .4s, visibility .4s ease;
  transition: opacity .4s, visibility .4s ease;
  &.visible {
    opacity: 1;
    visibility: visible; }
  &.left {
    right: 30px;
    &:before {
      left: 100%; } }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 7px 6px 7px;
    border-color: transparent transparent transparent #17272d;
    position: absolute;
    top: 10px;
    left: 180px; }
  li {
    list-style-type: none;
    float: left;
    width: 50%;
    border: 1px solid #293c43;
    font-size: 9px;
    height: 70px;
    position: relative;
    color: white;
    svg {
      font-size: 22px;
      color: white; }
    .menuItem {
        cursor: pointer;
        transform: translateY(30%);
        .itemText {
            padding-top: 5px;
            a {
                color: white; } } } } }

.preference {
  .btn.btn-gray {
    position: relative;
    overflow: visible;
    padding: 5px 15px 5px 15px;
    text-transform: none;
    &:before {
      content: ''; }
    &:after {
      display: none; }
    i {
      position: absolute;
      top: 50%;
      left: 6px;
      z-index: 25; }

    &:hover {
      color: $pref_green; } }
  &.new-preference {
    position: relative;
    text-align: center;
    h5 {
      padding-bottom: 15px; } } }

#preference-display-container {
  position: fixed;
  overflow-y: scroll;
  right: 0;
  bottom: 0;
  top: 40px;
  left: 250px;
  transition: all .5s; }

.pref_image_placeholder {
  border: 3px dotted #aaa;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle; }

.design {
  background-color: white;
  text-align:center {} }

.uploaded-pref-image-container {
  position: relative;
  display: inline-block;
  .text-input-form {
    display: none; }
  .text-input-group {
    max-width: 171px;
    margin: 0 auto;
    label {
      margin-top: 5px; } }
  .image-text-option, .text-input-option {
    padding: 4px 7px;
    margin: 15px 2px;
    border-radius: 20px;
    border: none;
    background: $light_gray;
    transition: all .5s ease;
    &.save-button {
      background: $pref_green; }
    &:hover {
      background: $dark_gray;
      color: #fff; } } }

.flex-item {
  min-width: 300px;
  background-color: white; }

.preview-phone {
  margin: 18px auto;
  width: 380px /* actual break point is 350, 380 accounts for padding in iframe display */;
  height: 80%;
  text-align: center;
  padding: 54px 12px 76px;
  position: relative;
  border: 3px solid #e0e0e0;
  border-radius: 35px;
  -ms-transition: all 0.25s cubic-bezier(0.7, 0, 0.175, 1) 0s;
  transition: all 0.25s cubic-bezier(0.7, 0, 0.175, 1) 0s; }

.preview-phone::before,
.preview-phone::after {
    content: "";
    position: absolute;
    border: 3px solid #e0e0e0;
    border-radius: 5px; }

.preview-phone::after {
    top: 25px;
    left: 140px;
    width: 70px;
    height: 6px; }

.preview-phone::before {
    bottom: 20px;
    left: 140px;
    width: 70px;
    height: 35px; }

.preview-tablet {
  margin: 18px auto;
  width: 798px  /* actual break point is 768, 798 accounts for padding display of iframe */;
  height: 80%;
  text-align: center;
  padding: 54px 12px 76px;
  position: relative;
  border: 3px solid #e0e0e0;
  border-radius: 35px;
  -ms-transition: all 0.25s cubic-bezier(0.7, 0, 0.175, 1) 0s;
  transition: all 0.25s cubic-bezier(0.7, 0, 0.175, 1) 0s; }

.preview-tablet::before {
    content: "";
    position: absolute;
    border: 3px solid #e0e0e0;
    border-radius: 5px; }

.preview-tablet::before {
    bottom: 20px;
    left: 44%;
    width: 70px;
    height: 35px; }

.preview-desktop {
  height: 100%; }


#template-preview-section {
  padding: 10px 10px 0;
  background-color: white;
  height: 100%; }

.required-error {
  border: 1px solid #ec5840 !important;
  background-color: rgba(236, 88, 64, 0.1) !important; }

.required-error-display {
  position: absolute;
  color: white;
  padding: 2px 5px 4px 5px;
  background-color: rgba(199, 43, 43, 0.97);
  right: 6px;
  top: 5px; }

.pref-ctr-input-column {
  width: 48%;
  input {
    width: 100%; } }

#pref-ctr-tab {
  height: 100%; }

.pref-tab {
  height: 100%; }

.pref-builder-input {
  position: relative; }

.pref-builder-input-90 {
  width: 90%; }

.pref-builder-input-margin {
  margin-top: 5px;
  margin-bottom: 15px; }

/** preferences home - grid container approach **/
.pref-home-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    display: grid;
    grid-gap: 10px; }

.history-container {
    padding 0 10px 0 10px {}
    .attribute {
        padding-top: 10px;
        font-weight: bold; }
    .attribute-value {
        padding-top: 5px; }
    .attribute-value-10 {
        padding-top: 10px; }
    .align-center {
        text-align: center; } }

.pref-input-column {
  input, textarea {
    width: 100%;
    resize: none;
    font-size: 14px; } }

.pref-input-column {
   textarea {
     height: 140px;
     font-family: Montserrat, Arial, sans-serif; } }

.section-head .section-actions {
  padding: 12px;
  text-align: right;
  text-transform: uppercase; }

.disabled-icon svg path {
    fill: gray; }

.input-border {
    border: 1px solid #d2d2d2; }
