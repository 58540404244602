//  section-preferences

.section-preferences {
  position: relative;
  padding-top: 3px;
  .section-head {
    min-height: 40px;
    position: relative;
    .section-actions {
      .btn {
        padding-left: 50px;
        + .btn {
          margin-left: 4px; }
        i {
          position: absolute;
          top: 50%;
          left: 18px;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%); } } } } }

//  section-guide

.section-guide {
  margin: 0 auto;
  background: #fff;
  .section-content {
    // display: none
    margin-left: 15px;
    margin-top: 15px;
    margin-right: 55px;
    &.current {
      display: block; } }
  .section-head {
    background: $pref_green;
    padding: 7px 15px;
    h4 {
      color: #fff;
      display: inline-block;
      position: relative;
      i {
        font-size: 16px;
        color: $dark_gray;
        line-height: 30px; }
      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: #fff;
        line-height: 30px;
        border-radius: 50%;
        color: $dark_gray;
        text-align: center;
        margin-right: 13px; } }
    .section-head-content {
      float: left; } }
  .section-nav {
    background: #ddd;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    li {
      list-style-type: none;
      display: inline-block;
      counter-increment: number-counter;
      padding: 1px 11px 0 0;
      position: relative;
      &:before {
        content: counter(number-counter);
        width: 20px;
        height: 19px;
        background: #a0a0a0;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        pointer-events: none;
        padding-top: 2px; }
      span {
        color: #a0a0a0;
        display: inline-block;
        padding-left: 28px;
        &:hover {
          text-decoration: none;
          color: $dark_gray;
          cursor: pointer; } }
      i {
        margin-left: 11px; }
      &.current {
        &:before {
          background: $dark_gray; }
        i, span {
          color: $dark_gray; } } } }
  .section-body {
    padding: 20px; }
  .section-actions {
    padding: 12px 25px;
    text-align: right;
    a {
      color: white;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
      display: inline-block;
      padding: 9px 14px;
      &.btn-cancel {
        background: #43545c;
        color: white;
        padding: 9px 14px;
        margin-left: 7px;
        &:hover {
          background: $med_gray; } }
      &:hover {
        text-decoration: none; }
      &.active-modal-button {
        background: $pref_green;
        color: white; }
      i {
        color: white;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px; }
      &.next {
        float: right;
        i {
          margin-left: 16px;
          position: relative;
          top: 1px; } }
      &.prev {
        float: left;
        color: white;
        &:hover {
          i {
            color: $dark_gray; }
          color: white; }
        i {
          margin-right: 16px;
          position: relative;
          top: 1px;
          color: #8e8e8e; } }
      &.deploy {
        color: #fff; } } }
  .section-content {
    &#template .section-body {
      padding: 24px 0; }
    &#design .section-actions, &#deployment .section-actions {
      border: none; }
    &#design .section-body {
      padding: 0; } } }

.section-body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    &:vertical {
      width: 11px; }
    &:horizontal {
      height: 11px; } }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5); }
  label {
    display: block;
    margin-top: 5px;
    font-weight: 500;
    text-align: left;
    font-size: 14px;
    &.deployment-field-label {
        font-weight: 600; }
    &.content-config-label {
        margin-top: 0;
        text-align: center; }
    &.sub-section-property-header {
        margin-top: 0;
        background-color: #d6d6d61f;
        border: 1px solid #d2d2d2;
        padding: 8px; }
    &.sub-section-property {
        padding-bottom: 10px;
        margin-top: 0; } }
  div {
    &.sub-section-property-header {
        font-weight: 500;
        text-align: left;
        font-size: 14px;
        margin-top: 0;
        background-color: #d6d6d61f;
        border: 1px solid #d2d2d2;
        padding: 3px 8px 3px 8px; } } }

.admin-section-body {
    label {
        display: block;
        margin-top: 5px;
        font-weight: 500;
        text-align: left;
        font-size: 14px;
        &.content-config-label {
            margin-top: 0;
            text-align: center; }
        &.sub-section-property-header {
            margin-top: 0;
            background-color: #d6d6d61f;
            border: 1px solid #d2d2d2;
            padding: 8px; }
        &.sub-section-property {
            padding-bottom: 10px;
            margin-top: 0; } } }

.section-content {
  padding-left: 15px;
  padding-right: 15px; }

.section-content-pref-ctr, .section-content-pref {
  padding: 15px;
  height: 100%; }

.sub-section-property-field {
    padding-bottom: 15px; }



