/* new override of react-select styles */

.react-select__control {
    min-height: auto !important;
    max-height: 30px !important;
    border-color: #d2d2d2 !important; }

.react-select__value-container {
    padding: 0 8px !important; }

.react-select__value-container--has-value {}
.react-select__indicators {}
.react-select__indicator-separator {
    margin-bottom: 4px !important;
    margin-top: 4px !important; }

.react-select__indicator {}
.react-select__single-value {}
.react-select__dropdown-indicator {
    padding: 4px !important; }

.react-select__clear-indicator {
    padding: 4px !important; }

.react-select__menu {}
.react-select__menu-list {}
.react-select__option {
    min-height: 30px !important; }

.react-select__input input {
    height: 20px;
    border: 0 !important; }

.react-select__input {
    height: 20px;
    border: 0 !important; }

.react-select__input-container {
    margin: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important; }

.react-select__group {
    padding-bottom: 0 !important;
    padding-top: 0 !important; }

.react-select__group-heading {
    font-size: 95% !important;
    margin-bottom: 0 !important; }

.react-select__control--is-focused {
    border-color: transparent !important; }

/** select - with flex height -- **/
.react-select-flex-height__control {
    min-height: auto !important;
    border-color: #d2d2d2 !important; }

.react-select-flex-height__value-container {
    padding: 0 8px !important; }

.react-select-flex-height__value-container--has-value {}
.react-select-flex-height__indicators {}
.react-select-flex-height__indicator-separator {
    margin-bottom: 4px !important;
    margin-top: 4px !important; }

.react-select-flex-height__indicator {}
.react-select-flex-height__single-value {}
.react-select-flex-height__dropdown-indicator {
    padding: 4px !important; }

.react-select-flex-height__clear-indicator {
    padding: 4px !important; }

.react-select-flex-height__menu {}
.react-select-flex-height__menu-list {}
.react-select-flex-height__option {
    min-height: 30px !important; }

.react-select-flex-height__input input {
    height: 20px; }

.react-select-flex-height__group {
    padding-bottom: 0 !important;
    padding-top: 0 !important; }

.react-select-flex-height__group-heading {
    font-size: 95% !important;
    margin-bottom: 0 !important; }

.react-select-flex-height__control--is-focused {
    border-color: transparent !important; }

/** related to ag-grid **/

.ag-paging-panel {
  display: flex; }

.ag-paging-row-summary-panel {
  flex: 1;
  text-align: left; }

.ag-menu {
  z-index: 1000000; }

.ag-paging-button {
  display: inline-block !important;
  background: #43545c !important;
  border-radius: 25px !important;
  text-transform: uppercase !important;
  border: 0 !important;
  padding: 4px 12px !important;
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 500 !important;
  position: relative !important;
  z-index: 5 !important;
  -webkit-transition: background .4s, color .4s ease !important;
  -o-transition: background .4s, color .4s ease !important;
  transition: background .4s, color .4s ease !important;
  opacity: 1 !important;
  width: initial !important;
  font-family: Montserrat, Arial, sans-serif !important;
  &:hover {
    text-decoration: none !important;
    color: #fff !important;
    background: $dark_gray !important; } }

.ag-theme-balham button:focus {
    box-shadow: none !important; }

.ag-theme-balham .ag-paging-page-summary-panel .ag-paging-button.ag-disabled .ag-iconq {
    color: white !important;
    font-weight: bold !important; }

.ag-theme-balham .ag-paging-page-summary-panel .ag-icon {
    color: white !important;
    font-weight: bold !important; }

.ag-header-cell-text {
    font-family: Montserrat, Arial, sans-serif;
    color: #313131; }

.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    &.cursor-pointer {
        border: initial !important; /* overrides default cell highlighting when clicked */; } }

.ag-filter-filter {
    font-family: Montserrat, Arial, sans-serif; }
.ag-text-field-input {
    font-family: Montserrat, Arial, sans-serif; }

.ag-picker-field-display {
    font-family: Montserrat, Arial, sans-serif; }

.ag-row-focus {
    z-index: 90; }

.ag-row.ag-row-no-focus {
    z-index: 0; }

.ag-input-field-input {
    height: 26px !important; }

.ag-theme-balham .ag-simple-filter-body-wrapper {
    padding: 8px !important;
    padding-bottom: 6px !important; }

//froala related
.fr-toolbar {
  color: black !important;
  -webkit-font-smoothing: auto !important; }

.fr-custom-color-picker-tab {
  padding: 9px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid transparent;
  cursor: pointer; }

.fr-custom-color-picker-tab-selected {
  border-bottom: 2px solid #0097a7;
  background-color: #e6e6e6;
  transition: all .5s ease; }


.fr-custom-color-picker-tab-container {
  font-size: 12px;
  background-color: #f5f5f5;
  color: #484545;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: -1px -1px 2px 0 rgba(0,0,0,0.3);
  -moz-box-shadow: -1px -1px 2px 0 rgba(0,0,0,0.3);
  box-shadow: -1px -1px 2px 0 rgba(0,0,0,0.3); }


//hack for monoaco editor bug in color selector
div {
    .colorpicker-body {
        .slider {
            &:before {
                height: 0 !important; } } } }

//react-phone-input
.PhoneInputCountryIcon--border {
    height: auto !important; }

.PhoneInputCountryIcon--border {
    box-shadow: none !important;
    background-color: inherit !important; }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: none !important; }

.__react_component_tooltip.show {
    opacity: 1 !important; }

.react-tel-input .form-control {
    border: 1px solid #d2d2d2 !important;
    line-height: normal !important;
    height: 30px !important;
    width: 100% !important; }

.react-tel-input .form-control.required-error {
    border: 1px solid #ec5840 !important;
    z-index: 101 !important; }

.react-tel-input .flag-dropdown {
    border: 1px solid #d2d2d2 !important; }

.react-tel-input .form-control {
    font-family: Montserrat, Arial, sans-serif !important; }

.react-tel-input .required-error-display {
    font-size: 14px;
    font-family: Montserrat, Arial, sans-serif !important; }

.adjust-zindex .react-tel-input .flag-dropdown {
    z-index: 100 !important; }

.amcharts-amexport-menu-level-0.amcharts-amexport-left {
    left: 37px !important;
    top: 77px !important; }

.amcharts-amexport-item:not([style]) {
    background-color: $light_gray !important;
    border: 1px solid #bbb !important; }

.amcharts-amexport-item[style=''] {
    background-color: $light_gray !important;
    border: 1px solid #bbb !important; }

.annotation-tooltip {
    z-index: 100; }

.chrome-picker {
    width: 245px !important; }

.Resizer.vertical {
  width: 8px;
  margin: 0 -4px;
  border-left: 4px solid rgba(255, 255, 255, 0);
  cursor: col-resize; }

.Resizer {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: $pref_green;
  opacity: .4;
  z-index: 1;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box; }

.Resizer.vertical:hover {
  border-right: 3px solid #33c3f0; }


.inline-editor-table {
    div.fr-element.fr-view {
        line-height: 28px;
        height: 28px;
        overflow-x: hidden;
        white-space: nowrap; } }

.inline-editor-table-desc {
    div.fr-element.fr-view {
        word-break: break-word;
        max-height: 100px;
        overflow-y: auto;
        line-height: initial; } }

.inline-img-editor {
    caret-color: transparent;
    div.fr-line-breaker {
        display: none; }
    div.fr-element.fr-view {
        cursor: defau; } }


//overrides of vendor props huka-carousel
//.carousel-container
////  .slider
//  //    height: 100% !important
//    //  .slider-frame
//      //    height: 100% !important
//        //  .slider-list
//          //    height: 100% !important
//            //  .slide-visible
//              //    height: calc(100% - 20px) !important
//      //.slider-control-bottomcenter
//        //  .paging-item
//          //    margin-right: 8px !important
