.container {
    background: $light_gray;
    padding: 30px 30px 30px 30px;
    &.container-secondary {
        float: left;
        width: 100%;
        padding: 15px; }
    &.report-container {
        float: left;
        width: 100%;
        height: 100%;
        padding: 15px; } }


.container-secondary {
  padding: 20px 15px 15px 15px; }
