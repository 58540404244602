.tab-container {
  overflow: hidden;
  //position: fixed
  //left: 40px
 }  //width: 100%

.tab-content {
  position: absolute;
  overflow: auto;
  top: 84px;
  left: 0;
  right: 0;
  bottom: 42px; }

.tab-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  right: 55px;
  width: 100%;
  max-width: 100%; }

.tab-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 42px;
  background-color: #ddd;
  .section-actions {
    padding: 6px;
    text-align: left;
    a {
        color: white;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1;
        display: inline-block;
        padding: 9px 14px; }
    .btn-cancel {
        background: #f2f2f2;
        color: #1F343D;
        padding: 8px 12px;
        margin-right: 7px; } } }
