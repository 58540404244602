
@media (max-width: 1300px) {
  .list-result li strong {
    display: block; } }

@media (max-width: 1180px) {
  //.profile
  ////width: 48%
  .list-result li strong {
    display: inline-block; } }

@media (max-width: 980px) {
  .list-result li strong {
    display: block; }
  .preferences {
    margin: 0; }
  .section-preferences .section-head .section-actions {
    position: static;
    padding-top: 10px; }
  .preference-nav {
    right: 30px;
    &:before {
      left: 100%; } }
  .mfp-auto-cursor .mfp-content {
    padding: 0 20px; } }

@media (max-width: 850px) {
  //.profile
  ////width: 100%
  ////padding: 0 0 19px
  ////float: none
  .list-result li strong {
    display: inline-block; }
  .list-choose li {
    width: 23%; } }


