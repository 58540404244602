.deploy-section {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  padding: 15px;
  .deploy-head {
    color: $dark_gray; }
  header {
    h6 {
        font-weight: 600; } } }

.deploy-actions {
  text-align: left; }

.deployment-field-label {
    font-weight: 600;
    margin-bottom: 5px; }

.pc-deployment-modal-copy-icon {
    top: 18px !important; }

//  code-container

.copy-container {
  position: relative;
  display: flex;
  width: 100%;
  .copyLink {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 34px;
    background: $pref_green;
    text-align: center;
    display: inline-block;
    line-height: 34px;
    color: #fff; }
  .copyIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 34px;
    background: $pref_green;
    text-align: center;
    display: inline-block;
    line-height: 34px;
    color: #fff !important;
    font-size: 16px !important; } }
