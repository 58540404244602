.design {
    background: #c0c0c0;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    .design-content {
        width: 100%;
        background: #c0c0c0;
        padding: 0 10px 10px 10px;
        display: flex;
        .content-layout {
            overflow: auto;
            width: 100%;
            border-radius: 15px; } }
    .design-aside {
        width: 100%;
        background: #fff; } }

.tenplate-container {
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin: auto; }

.cssEditor-actions {
    text-transform: uppercase;
    font-size: 12px; }

.prop-default-placeholder {
  &::-webkit-input-placeholder {
    background-color: rgba(58, 171, 71, 0.1);
    color: #6e797f; }
  &:-moz-placeholder {
    background-color: rgba(58, 171, 71, 0.1);
    color: #6e797f; }
  &::-moz-placeholder {
    background-color: rgba(58, 171, 71, 0.1);
    color: #6e797f; }
  &:-ms-input-placeholder {
    background-color: rgba(58, 171, 71, 0.1);
    color: #6e797f; } }

