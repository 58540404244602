  /*dashboard 2*/
.dashboard-container {
    padding: 20px;
    height: 100%;
    overflow: auto; }

.pref-center-dashboard {
    font-size: 15px;
    width: 100%;
    height: 100%;
    position: relative;

    .pie-chart {
        width: 100%;
        height: 100%; }

    label {
        display: block;
        font-weight: 500;
        text-align: left;
        font-size: 15px;
        cursor: pointer;
        &.sectionTitle {
            font-size: 16px;
            cursor: auto; }
        &.sectionLead {
            cursor: auto; } }
    .help {
        font-size: 21px;
        margin: 0; }

    .aggregate-total {
        font-weight: bold;
        font-size: 25px;
        text-align: center; }

    .trendIndicator {
        float: right;
        display: table-cell; }

    .amountChanged {
        width: 1%;
        text-align: right;
        padding-left: 10px;
        display: table-cell; }

    .statistic-row {
        cursor: pointer; }

    .statistic-cell {
        padding: 8px; }

    .statistic-cell-highlight {
        border: 1px solid $pref_green;
        background-color: #f3f3f3;
        border-radius: 4px; }

    .main-chart-layout {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-gap: 20px;
        height: 100%;
        .inner-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px; } }

    .dashboard-scope-container {
        display: flex;
        padding-bottom: 15px;
        min-height: 46px;
        align-items: center;
        overflow: hidden; } }

.arrow {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent; }

.arrow-up-green {
    border-bottom: 12px solid green; }

.arrow-up-red {
    border-bottom: 12px solid #d80000; }

.arrow-down-green {
    border-top: 12px solid green; }

.arrow-down-red {
    border-top: 12px solid #d80000; }

.arrow16 {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    margin-right: 4px;
    margin-bottom: 4px; }

.arrow-up-green16 {
    border-bottom: 13px solid green; }

.arrow-up-red16 {
    border-bottom: 13px solid #d80000; }

.arrow-down-green16 {
    border-top: 13px solid green; }

.arrow-down-red16 {
    border-top: 13px solid #d80000; }

.chart-title {
  font-size: 16px;
  font-weight: bold; }

.report-title {
  font-size: 16px;
  font-weight: bold; }

.report-options-slide-out {
    position: absolute;
    left: -1px;
    background-color: white;
    padding: 25px;
    border: 1px solid #bbb;
    box-shadow: #00000040 3px 3px 7px 3px; }


.viewer-container {
    .sub-act-dash {
        .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 1px 1px;
            grid-template-areas: ". ." ". .";
            gap: 15px; }
        .grid-cell {
            padding: 15px;
            border-radius: 15px; }
        .grid-color {
            background-color: #ffffff; } } }


.buffered-svg > div > svg {
    buffered-rendering: static;
    xshape-rendering: optimizeSpeed; }

.report-options-close {
    font-weight: bold;
    position: absolute;
    top: 4px;
    right: 4px;
    transition: color 150ms;
    color: #ccc;
    &:hover {
        color: #999; } }

.report-options-close > svg {
    fill: currentColor;
    stroke: currentColor;
    display: inline-block;
    stroke-width: 0;
    line-height: 1; }
