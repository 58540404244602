.search form {
  position: relative;
  font: 12px/1 Montserrat, Arial, sans-serif;
  .search-field {
    display: inline-block;
    width: 300px;
    border-radius: 25px;
    background: #fff;
    padding: 8px 45px 10px 20px;
    -webkit-appearance: none;
    border: none; }
  .hidden {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1; }
  .search-btn {
    width: 18px;
    height: 18px;
    border: none;
    -webkit-appearance: none;
    border-radius: 0;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;
    position: absolute;
    top: 7px;
    right: 20px;
    background-size: 18px 18px; } }
