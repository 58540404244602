.header {
  background: $pref_green;
  padding: 4px;
  &#pref-searchbar-header {
  	position: fixed;
  	top: 0;
  	right: 0;
  	left: 40px; }
  &#top-bar-header {
    position: fixed;
    padding: 0;
    top: 0;
    right: 0;
    left: 40px; } }

.top-bar {
    height: 40px;
    display: flex;
    align-items: center; }

.top-title {
    color: white;
    padding-left: 20px;
    font-size: 16px;
    text-transform: uppercase; }
