//  list-links

.list-links {
  font-size: 12px;
  line-height: 2.17;
  li {
    list-style-type: none;
    font-weight: 500;
    &.current a {
      font-weight: 700;
      color: $dark_gray; }
    a {
      display: block;
      color: #757479;
      &:hover {
        text-decoration: none;
        color: $dark_gray; } } } }

//  list-result

.list-result {
  padding: 42px 0 5px;
  font-size: 9px;
  color: #9ca2a5;
  font-weight: 300;
  line-height: 1;
  li {
    list-style-type: none;
    display: inline-block;
    width: 32%;
    text-align: left;
    position: relative;
    padding-left: 22px;
    i {
      position: absolute;
      top: 1px;
      left: 0; }
    strong {
      font-size: 20px;
      opacity: 0.7;
      color: $dark_gray;
      font-weight: 700;
      display: inline-block;
      vertical-align: middle; }
    span {
      display: inline-block;
      vertical-align: middle; } }
  &.size2 li {
    width: 50%;
    padding-left: 0; } }

//  list-choose

.list-choose {
  font-size: 0;
  margin: 0 -1%;
  li {
    list-style-type: none;
    border: 1px solid #d8d8d8;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
    a:hover {
      text-decoration: none;
      color: white; }
    strong {
      display: block;
      font-size: 10px;
      font-weight: 500;
      line-height: 1.56;
      padding: 6px 6px 7px;
      text-transform: uppercase; } } }
