.white {
  color: white; }

.center {
    text-align: center; }

.left-text {
    text-align: left; }

.block {
    display: block; }

.bold {
    font-weight: bold; }

.pad-5 {
  padding: 5px; }

.pad-left-4 {
  padding-left: 4px; }

.pad-4 {
  padding: 4px; }

.pad-10 {
  padding: 10px; }

.pad-15 {
  padding: 15px; }

.pad-20 {
  padding: 20px; }

.text-left {
    text-align: left; }

.pad-top-5 {
    padding-top: 5px; }

.pad-top-10 {
    padding-top: 10px; }

.pad-top-15 {
    padding-top: 15px; }

.pad-top-20 {
    padding-top: 20px; }

.pad-top-25 {
    padding-top: 25px; }

.pad-btm-5 {
    padding-bottom: 5px; }

.pad-btm-10 {
    padding-bottom: 10px; }

.pad-btm-15 {
    padding-bottom: 15px; }

.pad-btm-20 {
    padding-bottom: 20px; }

.pad-left-10 {
    padding-left: 10px; }

.pad-right-10 {
    padding-right: 10px; }

.mrg-top-15 {
    margin-top: 15px; }

.mrg-top-10 {
    margin-top: 10px; }

.mrg-btm-10 {
    margin-bottom: 10px; }

.mrg-btm-5 {
    margin-bottom: 5px; }

.list-style-none {
    list-style-type: none; }

.align-content-center {
    align-content: center; }

.display-flex {
    display: flex; }

.justify-center {
    justify-content: center; }

.justify-space-between {
    justify-content: space-between; }

.align-items-center {
    align-items: center; }

.display-none {
  display: none !important; }

.cursor-pointer {
    cursor: pointer; }

.cursor-not-allowed {
    cursor: not-allowed; }

.cursor-grab {
    cursor: grab; }

.cursor-default {
    cursor: default; }
