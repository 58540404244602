//.mfp-bg
/// opacity: .5

//.mfp-close-btn-in .mfp-close
//  display: none

.wrapper {
  max-width: 80%;
  margin: auto;
  > ul {
    line-height: 2rem;
    list-style-type: initial;
    font-size: 1.2rem;
    margin-top: 0;
    padding: 0; } }

.input-field {
  border: 1px solid #d2d2d2;
  text-indent: 4px;
  height: 30px;
  font-family: Montserrat, Arial, sans-serif;
  &.input-desc-inline-editor {
    text-indent: 0;
    height: auto; } }

.text-area-input {
  border: 1px solid #d2d2d2;
  padding: 4px;
  height: 80px;
  font-family: Montserrat, Arial, sans-serif; }

.help {
    color: #696969;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 17px; }

.preview-mods {
  height: 6%;
  max-height: 45px;
  min-height: 45px;
  margin: 11px 0 9px; }

.section-custom-template {
  text-transform: uppercase;
  text-align: center;
  display: block;
  padding: 0;
  margin-bottom: 18px;

  .btn {
    margin: 7px;
    padding: 9px 14px; } }

#green-preview-bar {
  width: 100%;
  min-height: 32px;
  max-height: 32px;
  height: 4%;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  background: $pref_green;
  padding: 2px 12px; }

#template-preview-section {
  .preview-mobile {
    width: 405px;
    height: 707px;
    border: 2px solid #696969;
    overflow-y: scroll;
    margin: 10px auto;
    border-radius: 35px;
    padding: 20px; } }

.ModalClass {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px; }

.modal-overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6) !important;
  z-index: 10000; }

.pref-ctr-ctx-overlay-class {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6) !important;
  z-index: 10000; }

.modal-z {
  z-index: 1000000 !important; }

.modal-actions {
  padding: 12px;
  text-align: center; }

.modal-actions a {
  text-transform: uppercase; }
