.sign-in-form-component {
    &.session-resume-component {
        padding: 30px;
        width: 415px;
        border-radius: 6px;
        background-color: white; }
    .login-panel {
        border: none;
        padding: 0px; } }

input:required {
    box-shadow: none; }

.logo {
	margin:auto; display: block {} }

.login-head-foot {
	color: #333;
	text-align: center; }

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 100%; }

.login-panel {
    border-radius: 6px;
    padding: 30px;
    width: 350px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #d2d2d2; }

.login-popup {
	position: relative;
	background: #FFFFFF;
	padding: 20px;
	width:auto {}
	max-width: 500px;
	margin: 20px auto; }

.login-field {
    width: 100%;
    border: 1px solid #d2d2d2;
    height: 30px;
    text-indent: 4px; }

.v-input-field {
    border: 1px solid #d2d2d2 !important;
    font-size: 24px !important;
    line-height: 37px !important; }

.v-input-container {
    height: 40px !important;
    width: 100% !important; }

.v-input-field-inactive {
    background-color: white !important; }

.v-input-field-active {
    outline: 2px solid black !important;
    color: initial !important;
    border: none !important; }

.label-weight {
    font-weight: 600; }

.error {
  color: #b42726;
  background-color: #fff8f8;
  border: 1px solid #e3a3a3;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  text-align: left; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

.password {
  display: block;
  position: relative;
  width: 100%;

  &__input {
    display: block; }

  &__show {
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 3px;
    bottom: 3px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid #727272;
    background-color: #727272;
    padding: 1px 8px;
    font-family: Montserrat, Arial, sans-serif; } }
